import React from "react";
import { Router } from "@reach/router";

import { useSanityGROQQuery } from "state/sanity";
import * as queries from "queries/sanity";

import Page from "templates/page";
import Product from "templates/product";

import Box from "components/Box";

const style = {
  wrapper: {
    "& > div[tabindex='-1']": {
      ":focus": {
        boxShadow: "none",
      },
    },
  },
  message: {
    textAlign: "center",
    p: 4,
  },
};

const PreviewPage = ({ id, rev }) => {
  React.useEffect(() => {
    // disable navigation to other pages inside preview
    window.___navigate = () => {
      return false;
    };
  }, []);

  const query = `*[_id == $id && _rev == $rev][0] { ${queries.page} }`;
  const variables = React.useMemo(() => ({ id, rev }), [id, rev]);
  const { data, error } = useSanityGROQQuery(query, variables);

  if (error) {
    return <Box sx={style.message}>Couldn't load preview data.</Box>;
  }

  if (!data) {
    return <Box sx={style.message}>Loading preview…</Box>;
  }

  switch (data._type) {
    case "page": {
      return <Page previewData={data} />;
    }
    case "product": {
      return <Product previewData={data} />;
    }
    default: {
      return (
        <Box sx={style.wrapper}>
          There's currently no preview component set up for documents of type
          {data._type}
        </Box>
      );
    }
  }
};

const Previews = () => {
  return (
    <Box sx={style.wrapper}>
      <Router>
        <PreviewPage path="/preview/:id/:rev" />
      </Router>
    </Box>
  );
};

export default Previews;
