export const formBlock = `
  _type == "formBlock" => {
    ...,
    form-> {
      ...
    }
  }
`;

export const galleryBlock = `
  _type == "galleryBlock" => {
    ...,
    images[] {
      ...,
      image {
        asset-> {
          ...
        }
      }
    }
  }
`;

export const blocks = `
  ...,
  ${[formBlock, galleryBlock].join(",\n")}
`;

export const pageSectionArtworkGrid = `
  _type == "pageSectionArtworkGrid" => {
    ...,
    artwork[]-> {
      ...
    } 
  }
`;

export const pageSectionDocumentList = `
  _type == "pageSectionDocumentList" => {
    ...,
    items {
      ...,
      items[]-> {
        ...
      }
    }
  }
`;

export const pageSectionDocumentLists = `
  _type == "pageSectionDocumentLists" => {
    ...,
    lists[] {
      ...,
      ${pageSectionDocumentList}
    }
  }
`;

export const pageSectionForm = `
  _type == "pageSectionForm" => {
    ...,
    form-> {
      ...
    }
  }
`;

export const pageSectionProductGrid = `
  _type == "pageSectionProductGrid" => {
    ...,
    products[]-> {
      ...
    }
  }
`;

export const pageSectionText = `
  _type == "pageSectionText" => {
    ...,
    blocks[] {
      ${blocks}
    }
  }
`;

export const pageSections = `
  ...,
  ${[
    pageSectionArtworkGrid,
    pageSectionDocumentList,
    pageSectionDocumentLists,
    pageSectionForm,
    pageSectionProductGrid,
    pageSectionText,
  ].join(",\n")}
`;

export const page = `
  _type == "page" => {
    ...,
    content {
      ...,
      main {
        ...,
        sections[] {
          ${pageSections}
        }    
      }
    }
  }
`;
